import React from "react"
import { useState } from "react";
import { CharacterInfo } from "../character/Character";

type CharacterThumbnailProps = {
  character: CharacterInfo;
  selectedCharacter: CharacterInfo | null;
  selectedCharacterChanged: React.Dispatch<React.SetStateAction<CharacterInfo | null>>;
}

export default function CharacterThumbnail(props: CharacterThumbnailProps) {

  const [character, setCharacter] = useState(props.character);

  return (
    <img 
      src={character.thumbnail_image} 
      alt={character.name} 
      className={"character-list-item " + (character.id === props.selectedCharacter?.id ? "selected" : "unselected") + ""}
      onClick={() => {
        props.selectedCharacterChanged(character);
      }}
    />
  )
}