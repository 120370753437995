import React, { useEffect } from "react";
import HomeSection from "./HomeSection";

export type Section = {
  id: string;
  title: string;
  content: string;
}

export default function HomeSections() {

  const [title ,setTitle] = React.useState<string>("");
  const [sections, setSections] = React.useState<Section[]>([]);

  useEffect(() => {
    if (sections.length > 0) {
      return;
    }
    fetch("/home.json").then((res) => {
      res.json().then((data) => {
        setTitle(data.title);
        setSections(data.sections);
      });
    });
  }, [])

  return (
    <div>
      <h1>{title}</h1>
      {generateHomeSections(sections)}
    </div>
  )
}

function generateHomeSections(sections: Section[]) {
  const elems: JSX.Element[] = [];
  for (const item of sections) {
    elems.push(
      <HomeSection
        key={item.id}
        title={item.title}
        content={item.content}
      />
    );
  }
  return (<>{elems}</>);
}