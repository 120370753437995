import React, { useEffect, useState } from "react";
import GalleryImages from "./GalleryImages";

export type GalleryItem = {
  id: string,
  order: number,
  title: string,
  url: string,
  thumbnail: string,
}

export default function Gallery() {

  const [images, setImages] = useState<GalleryItem[]>([]);

  useEffect(() => {
    if (images.length > 0) {
      return;
    }
    fetch("/gallery.json").then((res) => {
      res.json().then((data) => {        
        setImages(data);
      });
    });
  }, [])

  return (
    <div id="gallery-wrap" className="container">
      <GalleryImages
        images={images}
      ></GalleryImages>
    </div>
  )
}