import { GalleryItem } from "./Gallery"
import GalleryImage from "./GalleryImage";

export type GalleryImagesProps = {
  images: GalleryItem[]
}

export default function GalleryImages(props: GalleryImagesProps) {
  return (
    <div id="gallery-images">
      {genGalleryImage(props.images)}
    </div>
  )
}

// 画像を4つずつの行に分ける
function genGalleryImage(image: GalleryItem[]) {
  var rowImages = genImagesSeparatedArray(image, 4);
  var rows: JSX.Element[] = [];
  for (const row of rowImages) {
    rows.push(
      <div className="row gallery-thumbnail-row" key={row[0].id}>
        {genImagesRow(row)}
      </div>
    );
  }
  return (<>{rows}</>);
}

// 画像を1行分生成する
function genImagesRow(image: GalleryItem[]) {
  var elems: JSX.Element[] = [];
  for (const item of image) {
    elems.push(
      <GalleryImage
        item={item} 
        key={item.id}
      />
    );
  }
  return (<>{elems}</>);
}

// 配列を指定したサイズで分割する
function genImagesSeparatedArray(image: GalleryItem[], size: number) {
  var rowsImages = [];
  var rowImages = [];
  for (const item of image) {
    rowImages.push(item);
    if (rowImages.length >= size) {
      rowsImages.push(rowImages);
      rowImages = [];
    }
  }
  if (rowImages.length > 0) {
    rowsImages.push(rowImages);
  }
  return rowsImages;
}