import { useState } from "react"
import { GalleryItem } from "./Gallery"
import LightBox from 'bs5-lightbox';

export type GalleryImageProps = {
  item: GalleryItem
}

export default function GalleryImage(props: GalleryImageProps) {

  const [item, setItem] = useState(props.item)

  const options = {
    keyboard: true,
    size: 'xl'
  };

  return (
    <div className="col-3" >
      <div className="gallery-image">
        <a
          href={item.url}
          onClick={(e) => {
            e.preventDefault();
            const lightbox = new LightBox(e.currentTarget, options);
            lightbox.show();
          }}
        >
          <img src={item.thumbnail} alt={item.title} className="gallery-thumbnail" />
        </a>
      </div>
    </div>
  )
}