import React, { useEffect } from "react";

export default function HomeTwitter() {

  let widgetsLoaded = false;

  useEffect(() => {
    if (!widgetsLoaded) {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [])

  return (
    <>
      <a className="twitter-timeline" href="https://twitter.com/yunyalula?ref_src=twsrc%5Etfw" data-tweet-limit="5">Tweets by yunyalula</a> 
    </>
  )
}