import React from "react";
import { useEffect } from "react";
import CharacterList from "../character/CharacterList";
import CharacterMain from "./CharacterMain";

export type CharacterInfo = {
  id: string;
  name: string;
  caption_title: string;
  caption_body: string;
  dialog: string;
  main_image: string;
  thumbnail_image: string;
  background_image: string;
  sub_images: string[];
}

export default function Character(props: any) {

  const [characters, setCharacters] = React.useState<CharacterInfo[]>([]);
  const [selectedCharacter, setSelectedCharacter] = React.useState<CharacterInfo | null>(null);

  useEffect(() => {
    if (characters.length > 0) {
      return;
    }
    fetch("/characters.json").then((res) => {
      res.json().then((data) => {
        setCharacters(data);
        setSelectedCharacter(data[0]);
      });
    });
  }, [])

  useEffect(() => {
    console.log(characters);
  }, [characters]);

  useEffect(() => {
    console.log(selectedCharacter);
  }, [selectedCharacter]);

  return (
    <div id="character-wrap">
      <div id="character" className="character">
        <div id="character-contents">
          <div id="character-list-column">
            <CharacterList
              characters={characters}
              selectedCharacter={selectedCharacter}
              selectedCharacterChanged={setSelectedCharacter}
            ></CharacterList>
          </div>
          <div id="character-detail-column">
            <CharacterMain
              character={selectedCharacter}
            />
          </div>
        </div>
      </div>
    </div>
  )
}