
export type HomeSectionProps = {
  title: string;
  content: string;
}

export default function HomeSection(props: HomeSectionProps) {
  return (
    <div>
      <h2>{props.title}</h2>
      <p dangerouslySetInnerHTML={createMarkup(props.content)}></p>
    </div>
  );
}

function createMarkup(content: string) {
  return {__html: content};
}