import React, { useEffect } from "react";
import { CharacterInfo } from "../character/Character";
import CharacterThumbnail from "./CharacterThumbnail";

type CharacterListProps = {
  characters: CharacterInfo[];
  selectedCharacter: CharacterInfo | null;
  selectedCharacterChanged: React.Dispatch<React.SetStateAction<CharacterInfo | null>>;
}

export default function CharacterList(props: CharacterListProps) {

  const [characters, setCharacters] = React.useState<CharacterInfo[]>([]);

  useEffect(() => {
    setCharacters(props.characters);
  }, [props.characters])

  return (
    <div id="characters-list" className="char__list">
      <div className="character-select character-select-arrow-upper">↑↑↑</div>
      {genThumbnails(characters, props)}
      <div className="character-select character-select-arrow-lower">↓↓↓</div>
    </div>
  )

}

function genThumbnails(characters: CharacterInfo[], props: CharacterListProps) {
  const elems: JSX.Element[] = [];
  for (const item of characters) {
    elems.push(
      <CharacterThumbnail
        character={item} 
        selectedCharacter={props.selectedCharacter}
        selectedCharacterChanged={props.selectedCharacterChanged}
        key={item.id}
      />
    );
  }
  
  return (<>{elems}</>);
}