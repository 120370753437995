import { useEffect, useState } from "react";
import { CharacterInfo } from "./Character";

type CharacterDetailProps = {
  character: CharacterInfo | null;
}

export default function CharacterDetail(props: CharacterDetailProps) {

  const [character, setCharacter] = useState(props.character);

  useEffect(() => {
    setCharacter(props.character);
  }, [props.character])

  return (
    <div>
      <div id="character-name">{character?.name}</div>
      <div id="character-image" className="char__image">
        <img src={character?.main_image} alt={character?.name} />
      </div>
    </div>
  )

}