import React from 'react';
import './App.css';
import Navbar from './Navbar'
import Footer from './Footer'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './home/Home';
import Character from './character/Character';
import Gallery from './gallery/Gallery';

function App() {
  return (
    <div className="base-wrap">
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/character" element={<Character></Character>}></Route>
          <Route path="/gallery" element={<Gallery></Gallery>}></Route>
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
