import { useEffect, useState } from "react";
import { CharacterInfo } from "./Character";
import CharacterDetail from "./CharacterDetail";


type CharacterMainProps = {
  character: CharacterInfo | null;
}

export default function CharacterMain(props: CharacterMainProps) {

  const [character, setCharacter] = useState(props.character);

  useEffect(() => {
    setCharacter(props.character);
  }, [props.character])



  return (
    <div id="character-base">
      <div id="character-background">
        <img className="character-background-image" src={character?.background_image} alt={character?.name} />
      </div>
      <div id="character-main">
        <CharacterDetail
          character={character}
        />
      </div>
    </div>
  )
}