import React from "react";

export default function Footer() {
    return (
        <div className="footer footer-bottom bg-light">
            <div className="container">
                <div className="footer__content">
                    <p>2022 yunyalula</p>
                </div>
            </div>
        </div>
    )
}