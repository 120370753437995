import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
    return (
        <nav className="header navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
            <Link to={"/"}>yunyalula's page</Link>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to={"/"}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={"/character"}>Character</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={"/gallery"}>Gallery</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}