import React from "react";
import HomeSections from "./HomeSections";
import HomeTwitter from "./HomeTwitter";

export default function Home() {
    return (
        <div>
            <div className="p-3 p-sm-5 mb-4 cover-image">
                <div className="container">
                    <h2 className="display-4">yunyalula</h2>
                    <p className="lead">AIイラストの作成をしています。色んな絵を作るのが好き。</p>
                </div>
            </div>
            <div className="main container">
                <div className="main__content">
                    <div className="row">
                        <div className="col-8">
                            <HomeSections></HomeSections>
                        </div>
                        <div className="col-4">
                            <HomeTwitter></HomeTwitter>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}